@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

$fontFamily1: "Open Sans", sans-serif;
$Bluecolor1: #003844;
$Greencolor2: #006c67;
$Bluecolor3: #01B9F5;
$Bluecolor4: #F1F7FF;
$Bluecolor5: #8BA8C1;
$greycolor1: #D5D5D5;
$greencolor1: #006c67;
$greencolor2: #56D595;
$backgroundcolor: #F9F8F6;
$redcolor: #d32f2f;
$color1: #EDEDED;
$color2: #B5B5B5;
$color3: #FF6337;
$lightCreamcolor: #FEF6E4;

// common css 

.height100vh {
    height: 100%;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.CustomPasswordInput {

    input[type=password]::-ms-reveal,
    input[type=password]::-ms-clear {
        display: none;
    }
}

.customInputBox {
    .labelTag {
        font-family: $fontFamily1;
        color: #000000CC;
        font-size: 11px;
        font-weight: 400;
        line-height: 23px;
        text-align: left;

    }

    .inputGrp {
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid $Greencolor2;
        padding: 0px 5px 5px 5px;

        .inputTag {
            background-color: #fff;
            border: none;
            width: 100%;
            font-family: $fontFamily1;
            font-size: 14px;
            font-weight: 600;
            line-height: 23px;
            color: #000000CC;

            &:focus {
                border: none;
                outline: none;
            }
        }

        svg {
            cursor: pointer;
            color: $Bluecolor3;
        }
    }
}

.inputField1 {
    .MuiFormControl-root {
        .MuiFormLabel-root {
            color: #000;
        }

        .MuiInputBase-root {
            border-radius: 14px;
        }
    }

    .checkBoxSection {
        display: flex;
        align-items: center;

        .checkboxinput {
            accent-color: $Greencolor2
        }

        .labeltag {
            font-family: $fontFamily1;
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 0px;
            cursor: pointer;

            a {
                text-decoration: none;
                color: $color3;

                &:hover {
                    text-decoration: underline;
                }
            }

        }
    }

}

.error-text {
    color: $redcolor;
    margin: 3px 7px;
    font-size: 12px;

}

.button1 {
    background-color: $Greencolor2;
    border: none;
    width: 100%;
    box-shadow: 0px 0px 0px 8.56px #FFFFFF26;
    border-radius: 100px;
    font-family: $fontFamily1;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.07px;
    text-align: center;
    color: #fff;
    padding: 15px;
    transition: all 0.2s ease-in;

    &:hover {
        border: 1px solid $Greencolor2;
        background-color: #fff;
        color: $Greencolor2;
    }

    &.disabled {
        opacity: 0.5;
        cursor: no-drop;

        &:hover {
            background-color: $Greencolor2 !important;
            border: none !important;
            color: #fff !important;
        }
    }

}

.backbtn {
    border: none;
    border-radius: 6px;
    background-color: $color1;
    padding: 0px 5px;
    cursor: pointer;

    svg {
        font-size: 15px;
        margin: 7px 2px;
    }
}


// custom css

// Popup Box

.popup {
    .popup-background {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #00000073;
        z-index: 1000;
    }

    .outerBox {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1001;
    }

    .outerBox2 {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 1001;

    }

    @keyframes slideDown {
        0% {
            transform: translateY(-100%);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    /* Slide-out animation */
    @keyframes slideUp {
        0% {
            transform: translateY(0);
            opacity: 1;
        }
        100% {
            transform: translateY(-100%);
            opacity: 0;
        }
    }

    .popupBox1 {
        background-color: #fff;
        border-radius: 12px;
        position: relative;
        padding: 10px;
        max-width: 300px;
        margin: auto;
        top: 40%;

       

        .closeicon {
            text-align: end;

            .closebtntext {
                font-family: $fontFamily1;
                font-size: 16px;
                font-weight: 700;
                line-height: 21.79px;
                margin: 0px;

            }

            .closebtn {
                border: none;
                background-color: #fff;

                svg {
                    width: 20px;
                    height: 20px;
                    opacity: 50%;
                }
            }
        }

        .text {
            margin-top: 15px;
            text-align: center;

            h4 {
                font-family: $fontFamily1;
                font-size: 16px;
                font-weight: 700;
                line-height: 21.79px;
                color: #000;
            }

            h6 {
                font-family: $fontFamily1;
                font-size: 14px;
                font-weight: 400;
                line-height: 19.07px;
                color: #000000CC;

            }

            p {
                cursor: pointer;
                font-family: $fontFamily1;
                color: $Bluecolor3;
                font-size: 13px;
                font-weight: 600;
                line-height: 17.7px;
                margin: 20px 0px;
                text-align: center;

            }

            .selectcustomInput {
                .labelTag {
                    font-family: $fontFamily1;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16.34px;
                    color: #000;
                    margin: 8px 0px;

                }

                .MuiFormControl-root {
                    .MuiInputBase-root {
                        border-radius: 100px;
                        font-size: 14px;

                    }
                }
            }
        }

        &.secondPopup {
            position: relative;
            padding-top: 10px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 15px;
                border-radius: 12px;
                background: linear-gradient(90deg, #57C08B 0%, #08753E 100%);
                border-radius: 12px 12px 0 0;
            }
        }

        &.thirdPopup{
            background-color:transparent;
            border-radius: 0px;
            position: relative;
            padding: 0px;
            max-width: 475px;
            margin: auto;
            top: 0%;
            animation-duration: 0.5s;
            animation-timing-function: ease-in-out;
            &.show {
                animation-name: slideDown;
                animation-fill-mode: forwards;
            }
    
            &.hide {
                animation-name: slideUp;
                animation-fill-mode: forwards;
            }
        }
    }
}

// Message Box
.Messagebox {
    .subbox {
        background-color: rgba(255, 99, 55, 0.1);
        border-radius: 10px;
        border-left: 8px solid $color3;
        display: flex;
        align-items: center;
        padding: 20px 10px;

        h5 {
            font-family: $fontFamily1;
            font-size: 12px;
            font-weight: 400;
            line-height: 16.34px;
            color: $color3;
            margin-bottom: 0px;
        }
    }
}

// Verified Popup
.verifiedPopup {
    .circle {
        width: 300px;
        margin: 0px auto;
        height: 300px;
        padding: 10%;
        border-radius: 50%;
        background-color: rgba(0, 108, 103, 0.2);

        .imgs {
            text-align: center;
        }

        h4 {
            font-family: $Greencolor2;
            margin: 20px 0px;
            font-size: 16px;
            font-weight: 700;
            line-height: 21.79px;
            text-align: center;

        }
    }
}

.verifiedPopup-second {
    .content {
        padding: 10%;
        background: linear-gradient(90deg, #5CA19D 0%, #006C67 99.7%);
        border-radius: 12px;

        .imgs {
            text-align: center;
        }

        h2 {
            font-family: $Greencolor2;
            font-size: 25px;
            font-weight: 700;
            line-height: 21.79px;
            text-align: center;
            color: #fff;
            margin: 30px 0px;

        }

        h4 {
            color: #fff;
            font-family: $Greencolor2;
            margin: 30px 0px;
            font-size: 18px;
            font-weight: 700;
            line-height: 21.79px;
            text-align: center;
            white-space: nowrap;
        }
    }
}

// Slider Section css

.sliderSection {
    text-align: center;
    margin: 10px 0px;

    h6 {
        font-family: $fontFamily1;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: #000;
    }

    .MuiSlider-root {
        .MuiSlider-rail {
            background-color: rgba(0, 108, 103, 0.4);
        }

        .MuiSlider-track {
            // background: linear-gradient(122.52deg, #01B9F5 0%, #003F75 100.31%);
            background: linear-gradient(90deg, #5CA19D 0%, #006C67 99.7%);


        }

        .MuiSlider-thumb {
            width: 25px;
            height: 25px;
            color: #fff;
            border: 5px solid $Greencolor2 ;

        }

        .MuiSlider-mark {
            top: 90%;
            height: 10px;
            background-color: currentColor;
        }

        .MuiSlider-markLabel {
            top: 100%;
        }
    }

    h5 {
        font-family: $fontFamily1;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;
        border-radius: 100px;
        background-color: #006c67cc;
        display: inline-block;
        padding: 10px 20px;
        color: #fff;
    }
}


// Login Section css

.loginPage {
    // background-color: $backgroundcolor;

    .EmailloginView {
        .heading {
            padding-top: 15%;

            h3 {
                font-family: $fontFamily1;
                font-size: 30px;
                font-weight: 700;
            }

            h5 {
                font-family: $fontFamily1;
                font-size: 18px;
                color: $Bluecolor1;
            }

            h6 {
                font-family: $fontFamily1;
                font-size: 14px;
                font-weight: 400;
                line-height: 19.07px;

                span {
                    font-size: 18px;
                    font-weight: 700;
                }

            }
        }

        .loginButtons {
            .box {
                width: 100%;
                margin-top: 5px;
                background-color: #fff;
                border: 1px solid $greycolor1;
                border-radius: 100px;
                padding: 10px 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.2s ease-in;

                h6 {
                    margin-bottom: 0px;
                    font-family: $fontFamily1;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 21.79px;
                    color: $Bluecolor1;
                }

                &:hover {
                    background-color: $backgroundcolor;
                    border: 1px solid $Greencolor2;
                }
            }

            .orSection {
                margin: 20px 0px 50px 0px;
                display: flex;
                align-items: center;

                .lines {
                    height: 2px;
                    width: 100%;
                }

                h6 {
                    margin: 0px 10px;
                    font-family: $fontFamily1;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.07px;

                }
            }
        }

    }

    .otpVerificationView {
        padding-top: 10%;

        .heading {
            margin-top: 35px;

            h3 {
                font-family: $fontFamily1;
                font-size: 30px;
                font-weight: 700;
                line-height: 40.85px;
                text-align: left;
            }

            h5 {
                font-family: $fontFamily1;
                color: #000000CC;
                font-size: 13px;
                font-weight: 400;
                line-height: 18.35px;
                text-align: left;

                span {
                    color: $Greencolor2;
                    font-weight: 600;
                }

            }
        }

        .otpinputgrp {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;

            .inputsOTP {
                width: 15%;
                margin-top: 10px;
                padding: 10px;
                border: none;
                text-align: center;
                border-radius: 4px;
                background-color: $backgroundcolor;
                border-bottom: 1px solid $color2;
                color: $color2;
                font-family: $fontFamily1;
                font-size: 22px;
                font-weight: 600;
                line-height: 29.96px;

                &:focus-visible {
                    outline: none;
                }

                &.filled {
                    color: $Bluecolor1;
                    border-bottom: 1px solid $Bluecolor1;
                }

            }
        }

        .someText {
            display: flex;
            justify-content: space-between;

            p {
                margin-top: 10px;
            }

            .clickabletext {
                margin-left: auto;
                cursor: pointer;
                font-family: $fontFamily1;
                color: $Greencolor2;
                font-size: 14px;
                font-weight: 600;
                line-height: 19.07px;
                text-align: left;

            }

            .clickabletext-second {
                margin-left: auto;
                font-family: $fontFamily1;
                font-size: 14px;
                font-weight: 400;
                line-height: 19.77px;
                color: $color3;
            }
        }

        .btns {
            position: fixed;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            max-width: 450px;
            z-index: 10;
            text-align: center;

            .resendTimer {
                font-family: $fontFamily1;
                font-size: 14px;
                font-weight: 400;
                line-height: 19.77px;
                color: $color3;

            }

            .resendbtn {
                font-family: $fontFamily1;
                color: $Greencolor2;
                border: none;
                background-color: $backgroundcolor;
                font-size: 14px;
                font-weight: 700;
                line-height: 19.77px;

                &.disabled {
                    opacity: 0.5;
                    cursor: no-drop;
                }
            }
        }
    }

    .mobileLoginView {
        .heading {
            padding-top: 15%;

            h3 {
                font-family: $fontFamily1;
                font-size: 30px;
                font-weight: 700;
                line-height: 40.85px;

            }

            h5 {
                font-family: $fontFamily1;
                color: #000000CC;
                font-size: 13px;
                font-weight: 400;
                line-height: 18.35px;

            }
        }

        .btns {
            position: fixed;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            max-width: 450px;
            z-index: 10;
            text-align: center;
        }
    }
}

// Process Section css

.processMain {
    // background-color: $backgroundcolor;

    .header {
        margin-bottom: 20px;
        padding-top: 5%;
        display: flex;
        align-items: center;

        .LogoImage {
            margin: 0px auto;
            mix-blend-mode: darken;
        }
    }

    .ProcessSection {
        padding-bottom: 10px;

        h2 {
            font-family: $fontFamily1;
            font-size: 25px;
            font-weight: 700;
            line-height: 34.05px;

        }

        .ProcessBox {
            margin-top: 30px;
            border-radius: 10px;
            border: 1px solid #fff;
            background-color: #fbf8f8;
            // background-color: #fff;
            padding: 15px;

            .circle {
                margin: 0px auto;
                padding: 6px 8px;
                width: 40px;
                height: 40px;
                border: 1px solid $Greencolor2;
                background-color: $Bluecolor4;
                border-radius: 50%;
                box-shadow: 0px 8px 20px -11px #006c67;

                &.bg2 {
                    background-color: $Greencolor2;
                }
            }

            .lines {
                height: 7px;
                width: 1.5px;
                background-color: $greencolor1;
                margin: 2px auto;
                opacity: 0.5;
            }

            .text {
                h3 {
                    font-family: $fontFamily1;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 19.07px;

                }

                .verifybtn {
                    background-color: $Greencolor2;
                    color: #fff;
                    border-radius: 6px;
                    padding: 6px 15px;
                    border: none;
                    font-family: $fontFamily1;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16.34px;
                    text-align: center;
                    transition: all 0.2s ease-in;

                    &:hover {
                        border: 1px solid $Greencolor2;
                        background-color: #fff;
                        color: $Greencolor2;
                    }

                    &.disabled {
                        cursor: no-drop;
                        opacity: 0.3;

                        &:hover {
                            border: none !important;
                            background-color: $Greencolor2 !important;
                            color: #fff !important;
                        }
                    }

                    &.completed {
                        cursor: no-drop;

                        &:hover {
                            border: none !important;
                            background-color: $Greencolor2 !important;
                            color: #fff !important;
                        }
                    }
                }
            }
        }
    }
}

.processMainhorizontal {
    .ProcessBox {
        display: flex;
        width: 100%;
        overflow-x: auto;

        .process-row {
            display: flex;
            flex-wrap: nowrap;
        }

        .paddingRight {
            padding-right: 0px;
        }

        .paddingLeft {
            padding-left: 0px;
        }

        .circle {
            flex-shrink: 0;
            padding: 6px 8px;
            width: 40px;
            height: 40px;
            border: 1px solid $Greencolor2;
            background-color: $Bluecolor4;
            border-radius: 50%;
            box-shadow: 0px 8px 20px -11px #006c67;

            &.bg2 {
                background-color: $Greencolor2;
            }
        }

        .lines {
            border: none;
            border-top: 2px dashed $greencolor1;
            width: 65px;

            opacity: 0.5;
        }

        .text {

            h3 {
                padding-right: 10px;
                text-align: center;
                font-family: $fontFamily1;
                font-size: 12px;
                font-weight: 600;
                line-height: 19.07px;

            }


        }


    }
}

.MuiFormHelperText-root {
    margin-left: 6px !important;
}

.loan-offer {
    .loan-card-blue {
        margin-top: 20px;
        min-height: 100px;
        border-left: 10px solid $Bluecolor1;
        background-color: #e4e9ee;
        border-radius: 10px;
        text-align: center;
    }

    .loan-card-orange {
        margin-top: 20px;
        min-height: 100px;
        border-left: 10px solid #FFA323;
        background-color: #fbefda;
        border-radius: 10px;
        text-align: center;
    }

    .loan-card-red {
        min-height: 100px;
        margin-top: 20px;
        border-left: 10px solid #EB5757;
        background-color: #f8e8e7;
        border-radius: 10px;
        text-align: center;
    }

    .loan-btn {
        border: none;
        font-size: 14px;
        background: #006c67;
        border-radius: 20px;
    }

    hr {
        color: #BEBEBE !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        border-top: 1px solid !important;
    }

    .circle {
        width: 40px;
        padding: 5px;
        height: 40px;
        border-radius: 50%;
    }

    .lable-text {
        color: #7e776d;
        font-size: 12px;
    }

}

.uploaddocuments {
    .headingtext {
        h5 {
            font-family: $fontFamily1;
            color: #000;
            font-size: 16px;
            font-weight: 700;
            line-height: 21.79px;
            margin: 15px 0px;

        }
    }

    .tabs {
        border-radius: 100px;
        background-color: rgba(0, 108, 103, 0.1);
        display: flex;

        .subTabs {
            width: 50%;
            padding: 12px 15px;
            border-radius: 100px;

            h5 {
                margin-bottom: 0px;
                font-family: $fontFamily1;
                color: $Greencolor2;
                font-size: 14px;
                font-weight: 600;
                line-height: 19.07px;
                text-align: center;

            }

            &.active {
                background-color: $Greencolor2;

                h5 {
                    color: #fff;
                }
            }
        }
    }

    .inputgrp {
        display: flex;
        padding: 10px 6px;
        justify-content: center;

        .radioinput {
            width: 20px;
            height: 20px;
            accent-color: $Greencolor2;
            margin-right: 10px;
        }

        .labeltag {
            font-family: $fontFamily1;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            cursor: pointer;

        }
    }

    .uploadOption {
        margin-top: 20px;

        h4 {
            font-family: $fontFamily1;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: $Greencolor2;
        }

        .inputGrp {
            .fileinput {
                border: none;
                width: 100%;
                border-radius: 100px;
            }
        }

        .uploadedDocs {
            border: 1px solid $greencolor1;
            border-radius: 12px;
            padding: 10px;
            margin-top: 30px;
            position: relative;
            z-index: 1;

            .uploadedLabel {
                border-radius: 6px;
                background-color: rgba(0, 63, 117, 0.3);
                width: 100%;
                padding: 15px;
                text-align: center;
                font-family: $fontFamily1;
                color: $Greencolor2;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;

            }

            .imgs {
                position: absolute;
                text-align: center;
                width: 100%;
            }
        }

        .passwordsection {
            margin-top: 30px;
            display: flex;
            align-items: center;

            h5 {
                font-family: $fontFamily1;
                font-size: 14px;
                font-weight: 400;
                line-height: 19.07px;
                text-align: left;
                margin-bottom: 0px;
                margin-right: 10px;
            }
        }

        .noteSection {
            font-family: $fontFamily1;
            color: $color3;
            font-size: 12px;
            font-weight: 400;
            line-height: 16.34px;
            text-align: left;

        }

        .passwordInput {
            .MuiInputBase-root {
                border-radius: 100px;
                color: $Greencolor2;

                .MuiButtonBase-root {
                    svg {
                        color: $Greencolor2;

                    }
                }
            }

            p {
                margin-top: 6px;
                font-family: $fontFamily1;
                font-size: 12px;
                font-weight: 400;
                line-height: 16.34px;
                text-align: left;
                color: $Greencolor2;
            }
        }
    }

    .takeSelfie {
        p {
            font-family: $fontFamily1;
            margin-top: 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $Greencolor2;
        }

        .selfieBtn {
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 5%;
            // bottom: 15%;

            .camerabtn {
                height: 75px;
                width: 75px;
                margin: 0px auto;
                padding: 18px;
                border-radius: 50%;
                background-color: $Greencolor2;
                color: #fff;
                border: none;
                transition: all 0.2s ease-in;

                svg {
                    font-size: 40px;
                }

                .camerabtn-input {
                    position: absolute;
                    top: 0;
                    // left: 0;
                    height: 75px;
                    width: 75px;
                    z-index: 2;
                    border-radius: 50%;
                    opacity: 0;
                    cursor: pointer;
                    transform: translate(-19px, 0px);
                }

                &:hover {
                    border: 1px solid $Greencolor2;
                    color: $Greencolor2;
                    background-color: #fff;
                }
            }

            h6 {
                font-family: $fontFamily1;
                font-size: 16px;
                font-weight: 700;
                line-height: 21.79px;
                margin-top: 15px;
                color: $Greencolor2;
            }
        }
    }

    .SelfieImg {
        margin: 50px 0px 40px 0px;

        .imgs {
            margin: 0px auto;
            width: 180px;
            height: 220px;
            border-radius: 12px;
        }

        .userImage {
            width: 180px;
            height: 220px;
            border-radius: 12px;
            background: #71717199;
            opacity: 0.6;

            svg {
                color: #000;
                font-size: 185px;
            }
        }
    }

    .btns {
        h6 {
            font-family: $fontFamily1;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.07px;
            text-align: center;
            color: $color3;

            span {
                color: $Greencolor2;
                cursor: pointer;
            }
        }
    }
}

.loanapplication {
    .loanDetails {
        padding: 20px;

        .head {
            h5 {
                font-family: $fontFamily1;
                font-size: 16px;
                font-weight: 700;
                line-height: 21.79px;
                text-align: left;
                color: #000;
                margin-bottom: 0px;
                padding: 10px 0px;
                border-bottom: 1px solid rgba(0, 63, 117, 0.2);
            }
        }

        .infoTabs {
            .tabs {
                display: flex;
                justify-content: space-between;

                .left {
                    margin: 8px 0px;
                    font-family: $fontFamily1;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    text-align: left;
                    color: rgba(0, 63, 117, 0.8);
                }

                .right {
                    margin: 8px 0px;
                    font-family: $fontFamily1;
                    color: $Greencolor2;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16px;
                    text-align: right;

                }
            }
        }

        .loanSlidersSection {
            .sectionss {
                padding: 20px;
                border-top: 1px solid rgba(0, 63, 117, 0.8);

                .heading {
                    h5 {
                        font-family: $fontFamily1;
                        color: $Greencolor2;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 16.34px;
                        text-align: left;

                    }
                }

            }
        }

    }

    .appliedLoanSection {
        margin: 15px 0px;
        padding: 10px;
        background-color: #f0f0f0;
        border-radius: 12px;

        .heading {
            h5 {
                font-family: $fontFamily1;
                font-size: 16px;
                font-weight: 700;
                line-height: 21.79px;
                text-align: left;
                color: #000;
            }
        }

        .tabs {
            margin: 10px 0px;

            .subTab {
                padding: 8px 0px;
                display: flex;
                justify-content: space-between;

                .left {
                    font-family: $fontFamily1;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;
                    color: #000;
                }

                .right {
                    max-width: 150px;
                    font-family: $fontFamily1;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16px;
                    text-align: right;
                    color: $Greencolor2;
                }
            }
        }
    }
}


// Main Dashboard CSS
.DashboardHeader-first {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 1.9px;
    right: 2px;
    margin: 0px auto;
    max-width: 480px;
    background: #fff;

    .header {
        padding: 10px 0px;

        svg {
            cursor: pointer;
        }

        h5 {
            font-family: $fontFamily1;
            color: $Greencolor2;
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;

        }

        .bellicon {
            svg {
                color: $Greencolor2;
                font-size: 32px;
            }
        }

    }
}


.Maindashboard {
    overflow-y: auto;
    position: relative;
    z-index: 1;

    .bottomNavbar {
        position: fixed;
        z-index: 101;
        left: 1.9px;
        right: 2px;
        bottom: 0;
        margin: 0px auto;
        max-width: 480px;
        background-color: #fff;

        .outerTab {
            background-color: rgba(0, 108, 103,0.2);

        }

        .tabs {
            display: flex;
            justify-content: space-evenly;

            .subTabs {
                width: calc(100%/5);
                cursor: pointer;
                text-align: center;

                .borderTopDiv {
                    width: 70%;
                    height: 7px;
                    background: $Greencolor2;
                    border-bottom-left-radius: 36px;
                    margin: 0px auto;
                    border-bottom-right-radius: 36px;
                    opacity: 0;
                }

                h5 {
                    margin-top: 0px !important;
                    font-family: $fontFamily1;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 16px;
                    color: $Bluecolor5;

                }

                &.active {

                    backdrop-filter: blur(9px);
                    background: linear-gradient(180deg, rgba(0, 108, 103, 0.23) 0%, rgba(0, 108, 103, 0.1173) 100%);

                    .borderTopDiv {
                        opacity: 1 !important;
                    }

                    h5 {
                        color: $Greencolor2 !important;
                    }
                }
            }
        }
    }

    .sidebarMenu {
        background: #006c67;
        height: 100vh;
        padding: 15px;
        position: relative;
        z-index: 1;

        .closeIcon {
            svg {
                color: #fff;
            }
        }

        .links {

            ul {

                padding-left: 0px;
                list-style-type: none;
                margin-top: 20px;

                li {
                    .linkdiv {
                        white-space: nowrap;
                        cursor: pointer;
                        padding: 12px 0px;
                        display: inline-flex;
                        align-items: center;

                        img {
                            margin-right: 15px;
                        }

                        h5 {
                            font-family: $fontFamily1;
                            margin-bottom: 0px;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 20px;
                            text-align: left;
                            color: #fff;
                        }


                    }

                    &.logoutLink {
                        position: absolute;
                        bottom: 15%;
                        z-index: 0;
                    }
                }
            }
        }
    }

    .OverflowContent {
        padding: 55px 0px 90px 0px;
        height: 100%;
        overflow-y: auto;
    }

    .DashboardHome {
        .userInfo {
            margin-top: 20px;

            .imgs {
                position: relative;
                z-index: 1;

                .circle {
                    margin: 0px auto;
                    width: 65px;
                    height: 65px;
                    border-radius: 50%;
                    background-color: $Greencolor2;
                }

                .editbtn {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    cursor: pointer;

                    .circle2 {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-color: $Bluecolor1;
                        padding: 0px 6px;
                        cursor: pointer;

                        svg {
                            font-size: 18px;
                            color: #fff;
                        }
                    }
                }
            }

            .text {
                h3 {
                    font-family: $fontFamily1;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24.51px;
                    margin-bottom: 5px;

                }

                h5 {
                    font-family: $fontFamily1;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16.34px;
                    margin-bottom: 5px;
                    color: #000000CC;

                }

                h6 {
                    font-family: $fontFamily1;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 16.34px;
                    margin-bottom: 5px;
                    color: #000000CC;

                }
            }
        }

        .cibilScore {
            margin-top: 30px;

            h4 {
                font-family: $fontFamily1;
                font-size: 12px;
                font-weight: 700;
                line-height: 16.34px;

            }

            .scoreBox {
                background: $Greencolor2;
                border-radius: 12px;
                padding: 20px 0px;

                .piechart {
                    height: 100px;
                }

                .text {
                    h3 {
                        font-family: $fontFamily1;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 19.07px;

                    }

                    h5 {
                        font-family: Open Sans;
                        font-size: 11px;
                        font-weight: 400;
                        line-height: 14.98px;
                        text-align: left;
                        color: #fff;
                    }

                    .check-btn {

                        border: 1px solid #fff;
                        border-radius: 100px;
                        padding: 8px 20px;
                        color: #fff;
                        font-family: $fontFamily1;
                        background: transparent;
                        font-size: 10px;
                        font-weight: 600;
                        line-height: 13.62px;
                        text-align: center;

                        &:hover {
                            background-color: #fff;
                            color: $Greencolor2;
                        }

                    }
                }
            }
        }

        .LoanReport {
            margin-top: 20px;

            .toptabs {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;

                h5 {
                    margin-top: 5px;
                    font-family: $fontFamily1;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16.34px;
                    text-align: left;
                    margin-bottom: 0px;
                    color: #000;
                }

                h6 {
                    margin-bottom: 0px;
                    margin-top: 5px;
                    font-family: $fontFamily1;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16.34px;
                    color: $Bluecolor3;
                    text-align: left;
                    cursor: pointer;
                }

                h4 {
                    font-family: $fontFamily1;
                    color: #000000B2;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16.34px;

                    span {
                        font-size: 14px;
                        color: #000;

                    }

                }
            }

            .box {
                position: relative;
                z-index: 1;
                border-radius: 12px;
                border: 1px solid rgba(0, 108, 103, 0.4);
                background-color: #fff;
                padding: 20px 10px;
                width: 100%;
                overflow: hidden;

                .subbox {
                    position: relative;
                    z-index: 2;
                    text-align: center;

                    .circle {
                        margin: 0px auto;
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        padding: 8px;
                        background-color: $Greencolor2;

                        &.color-lightBlue {
                            background-color: $Bluecolor3;
                        }

                        &.color-lightblue2 {
                            background-color: rgba(0, 63, 117, 0.3);
                        }
                    }

                    h5 {
                        margin: 8px 0px 5px 0px;
                        font-family: $fontFamily1;
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 13.62px;

                    }

                    h4 {
                        font-family: $fontFamily1;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 16px;
                        color: $Greencolor2;
                        margin: 10px 0px;

                    }

                    h6 {
                        font-family: $fontFamily1;
                        color: $Greencolor2;
                        font-size: 10px;
                        font-weight: 600;
                        line-height: 13.62px;

                    }


                }

                .secondSubBox {
                    .HeadText {
                        border-bottom: 1px solid $greencolor1;
                        padding: 6px 5px;
                        display: flex;
                        justify-content: space-between;

                        h5 {
                            margin-bottom: 0px;
                            font-family: $fontFamily1;
                            font-size: 10px;
                            font-weight: 600;
                            line-height: 13.62px;
                            color: #777777;

                        }

                        h6 {
                            margin-bottom: 0px;
                            font-family: $fontFamily1;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 16px;
                            color: $Greencolor2;

                        }
                    }

                    .tabss {
                        display: flex;

                        .tab {
                            padding-top: 10px;
                            width: 50%;
                            text-align: center;

                            h5 {
                                font-family: $fontFamily1;
                                font-size: 10px;
                                font-weight: 400;
                                line-height: 13.62px;
                                color: #777777;


                            }

                            h6 {
                                font-family: $fontFamily1;
                                font-size: 14px;
                                font-weight: 700;
                                color: $Greencolor2;
                                line-height: 13.62px;
                            }

                            &.borderRight {
                                border-right: 1px solid $greencolor1;
                            }
                        }

                    }
                }

                .backimg {
                    background-image: url('./images/loanActivityBg.svg');
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-size: 40%;
                    z-index: 0;

                }

                .hrtag {
                    border: 1.5px solid $Greencolor2;
                    opacity: 1;
                }
            }
        }

        .loanActivity {
            margin-top: 20px;

            h5 {
                font-family: $fontFamily1;
                font-size: 12px;
                font-weight: 700;
                line-height: 16.34px;
                text-align: left;
                color: #000;
            }

            .loanActivityBox {
                margin-top: 15px;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                padding: 15px 10px;
                border: 1px solid rgba(0, 108, 103, 0.3);

                .topText {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid $greencolor1;
                    padding: 6px 5px;

                    h6 {
                        font-family: $fontFamily1;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        text-align: left;

                        span {
                            font-weight: 600;
                        }

                    }
                }

                .circle {
                    margin: 0px auto;
                    background-color: $Greencolor2;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    padding: 8px;
                }

                .text {
                    h5 {
                        font-family: $fontFamily1;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16.34px;
                        color: #000000B2;
                    }

                    h6 {
                        font-family: Open Sans;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 14.98px;
                        color: $Greencolor2;

                        span {
                            font-size: 11px;
                        }

                    }
                }

                h3 {
                    font-family: $fontFamily1;
                    padding: 5px 15px;
                    border-radius: 100px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;
                    text-align: center;

                }
            }
        }

        .payUpcomingEMiSection {
            margin-top: 20px;
            padding: 20px 10px;
            background-color: rgba(0, 63, 117, 0.1);
            border-radius: 12px;
            border: 1px dashed $Greencolor2;
            text-align: center;

            h3 {
                color: $Greencolor2;
                font-size: 14px;
                font-weight: 500;
                font-family: $fontFamily1;
                margin: 10px 0px;
            }

            h4 {
                color: $Greencolor2;
                font-size: 18px;
                font-weight: 700;
                font-family: $fontFamily1;
                margin: 10px 0px;
            }

            h5 {
                color: $Bluecolor3;
                font-size: 12px;
                font-weight: 700;
                font-family: $fontFamily1;
                margin: 10px 0px;
            }
        }

        .rewardOfferSection {
            margin-top: 20px;
            margin-bottom: 30px;

            h5 {
                font-family: $fontFamily1;
                color: #000;
                font-size: 12px;
                font-weight: 700;
                line-height: 16.34px;

            }

            .offersSection {
                overflow-x: auto;
                display: flex;
                flex-wrap: nowrap;

                .tab1 {
                    width: 40%;
                    margin-top: 10px;
                    border-radius: 12px;
                    position: relative;
                    z-index: 1;
                    margin-right: 15px;

                    .bgimg {
                        border-radius: 12px;
                        position: absolute;
                        background-image: url('./images/rewardimg1.jfif');
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }

                    .bgimg2 {
                        border-radius: 12px;
                        position: absolute;
                        background-image: url('./images/rewardimg2.jfif');
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }

                    .text {
                        padding: 10px;
                        position: relative;
                        z-index: 2;

                        h4 {
                            font-family: $fontFamily1;
                            color: #fff;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 16.34px;

                        }

                        .btns {
                            margin-top: 50px;

                            .claimNow {
                                border-radius: 12px;
                                border: none;
                                background-color: #fff;
                                color: $Greencolor2;
                                padding: 2px 10px;
                                font-size: 12px;
                                white-space: nowrap;

                                &:hover {

                                    background-color: $Greencolor2;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .PaymentRecievedScreen {
        .Payment {
            margin-top: 40px;

            .imgs {
                text-align: center;
            }

            h3 {
                font-family: $fontFamily1;
                margin-top: 20px;
                font-size: 25px;
                font-weight: 700;
                line-height: 34.05px;
                letter-spacing: -0.30000001192092896px;
                text-align: center;
                color: #000;
            }

            h4 {
                font-family: $fontFamily1;
                font-size: 18px;
                color: #000000B2;
                font-weight: 600;
                line-height: 24.51px;
                letter-spacing: -0.30000001192092896px;
                text-align: center;

            }

            h2 {
                font-family: $fontFamily1;
                font-size: 30px;
                font-weight: 700;
                line-height: 16px;
                color: $Greencolor2;
                margin: 25px 0px 0px 0px;
                text-align: center;

            }

            .statsValues {
                margin-top: 25px;
                padding: 0px 20px;

                .box {
                    border: 1px solid #006c6733;
                    padding: 20px 10px 0px 10px;

                    .tabs {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;

                        h5 {
                            font-family: $fontFamily1;
                            margin-bottom: 0px;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            text-align: left;
                            color: #000000CC;

                        }

                        h4 {
                            font-family: $fontFamily1;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 24px;
                            text-align: left;
                            margin-bottom: 0px;

                        }
                    }
                }
            }

            .paymentSliders {
                padding: 10px;

                h5 {
                    font-family: $fontFamily1;
                    margin-top: 30px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 19.07px;
                    letter-spacing: -0.30000001192092896px;
                    text-align: center;
                    color: #000000B2;

                }

                .progressBar {
                    display: flex;
                    align-items: center;

                    .progressBartrack {
                        width: 100%;
                        margin-right: 10px;
                        border-radius: 12px;
                        height: 7px;
                        background-color: rgba(0, 63, 117, 0.1);

                        .progressBarrail {
                            border-radius: 12px;
                            height: 7px;
                            background: linear-gradient(360deg, #003F75 0%, #0070A9 52.78%, #01AFEB 99.13%);
                        }
                    }

                    h6 {
                        font-family: $Greencolor2;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 16.34px;
                        letter-spacing: -0.30000001192092896px;
                        margin-bottom: 0px;
                        color: $Greencolor2;
                    }
                }

                .tabs {
                    display: flex;
                    justify-content: space-between;

                    .tab {
                        margin-top: 10px;

                        p {
                            font-family: $fontFamily1;
                            margin-bottom: 5px;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16.34px;
                            letter-spacing: -0.30000001192092896px;
                            color: #000000B2;
                        }

                        h6 {
                            font-family: $fontFamily1;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 16px;
                            color: #000;

                        }
                    }
                }
            }
        }
    }

    .loanReportSection {
        .loanReport {
            margin: 20px 0px;

            .date {
                h5 {
                    font-family: Open Sans;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px;
                    text-align: right;
                    color: #000;
                }
            }

            .reportBox {
                margin-top: 15px;
                border: 1px solid rgba(0, 63, 117, 0.1);
                padding: 20px 15px;

                .topData {
                    padding-bottom: 15px;
                    border-bottom: 1px solid rgba(0, 63, 117, 0.1);

                    .circle {
                        background-color: rgba(0, 108, 103, 0.1);
                        margin: 0px auto;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        padding: 8px;
                    }

                    .text {
                        h5 {
                            font-family: $fontFamily1;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            text-align: left;

                            span {
                                font-weight: 600;
                            }
                        }

                        h6 {
                            font-family: $fontFamily1;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 18px;
                            text-align: left;
                            color: $color2;
                        }
                    }

                    h3 {
                        border-radius: 100px;
                        padding: 3px 8px;
                        color: #fff;
                        font-family: $fontFamily1;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 18px;
                        text-align: center;

                    }
                }

                .tabs {
                    margin-top: 20px;

                    .tab {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 6px 0px;

                        h5 {
                            color: rgba(0, 63, 117, 0.6);
                            font-family: $fontFamily1;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 16px;
                            text-align: left;

                        }

                        h6 {
                            font-family: $fontFamily1;
                            max-width: 150px;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 16px;
                            text-align: right;

                        }
                    }
                }

                .btns {
                    text-align: center;

                    .emiDetailsbtn {
                        margin-top: 20px;
                        background-color: #fff;
                        color: $Greencolor2;
                        font-family: $fontFamily1;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 19.07px;
                        text-align: center;

                        border: none;

                        &:hover {
                            text-decoration: underline;

                        }
                    }
                }
            }
        }
    }

    .viewEMIDetailsSection {
        .viewDetails {
            margin-top: 20px;

            .emiData {
                border: 1px solid rgba(0, 108, 103, 0.1);

                .topData {
                    padding: 10px 6px;
                    background-color: rgba(0, 108, 103, 0.1);

                    .square {
                        background-color: #fff;
                        margin: 0px auto;
                        width: 50px;
                        height: 50px;
                        border-radius: 6px;
                        padding: 8px;
                    }

                    .text {
                        display: flex;
                        justify-content: space-between;

                        h5 {
                            font-family: $fontFamily1;
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 18px;
                            text-align: left;
                            color: #000;
                            margin-bottom: 4px;

                        }

                        h6 {
                            font-family: $fontFamily1;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 18px;
                            text-align: left;
                            color: #000;
                        }
                    }
                }

                .detailsTabs {
                    display: flex;
                    justify-content: space-between;

                    padding: 10px 10px 0px 10px;

                    h5 {
                        font-family: $fontFamily1;
                        color: #000;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        text-align: left;
                        margin-bottom: 0px;
                    }

                    h6 {
                        margin-bottom: 0px;
                        font-family: $fontFamily1;
                        color: rgba(0, 63, 117, 0.2);
                        font-size: 10px;
                        font-weight: 600;
                        line-height: 16px;
                        text-align: left;

                        span {
                            color: $Greencolor2;
                        }
                    }
                }

                .emisDetails {
                    padding: 10px;

                    .emiTabs {
                        margin-top: 10px;
                        padding: 10px;
                        overflow: hidden;
                        transition: max-height 0.3s ease-out;
                        max-height: 70px;

                        &.open {
                            max-height: 200px;
                        }

                        &:nth-child(odd) {
                            background-color: $Bluecolor4;
                        }

                        &:nth-child(even) {
                            background-color: rgba(0, 108, 103, 0.2);
                        }

                        .text {
                            h5 {
                                font-family: $fontFamily1;
                                font-size: 10px;
                                font-weight: 400;
                                line-height: 16px;
                                text-align: center;
                                color: #000000B2;
                                margin-bottom: 8px;

                            }

                            h6 {
                                font-family: $fontFamily1;
                                color: $Greencolor2;

                                font-size: 13px;
                                font-weight: 600;
                                line-height: 16px;
                                text-align: center;

                            }
                        }
                    }
                }

            }

            .contactusOption {
                background: $lightCreamcolor;
                padding: 15px 20px 10px 20px;
                margin: 30px 25px 0px 25px;

                h5 {
                    font-family: $fontFamily1;
                    color: #000;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16px;

                }

                h6 {
                    font-family: $fontFamily1;
                    color: #000000B2;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;

                }

                .circle {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    margin-left: auto;
                    padding: 10px;
                    background-color: $greencolor2;

                    svg {
                        color: #fff;
                    }
                }
            }
        }
    }

    .calculatorSection {
        .piechart {
            text-align: center;
            height: 180px;
        }

        .Pielegends {
            margin-top: 20px;

            .legend {
                text-align: center;

                .square {
                    width: 8px;
                    height: 8px;
                    margin: 5px auto;
                }

                h5 {
                    font-family: $fontFamily1;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 16px;
                    color: #000000B2;


                }

                h4 {
                    font-family: $fontFamily1;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16px;
                    color: #000000CC;


                }
            }

            .textData {
                margin-top: 15px;
                border-radius: 12px;
                background: rgba(0, 108, 103, 0.6);
                display: flex;
                justify-content: space-between;
                padding: 10px 15px;

                h4 {
                    font-family: $fontFamily1;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18.5px;

                    margin: 4px 0px;
                }

                h5 {
                    font-family: $fontFamily1;
                    color: #fff;
                    font-size: 11px;
                    font-weight: 300;
                    line-height: 18.5px;
                    margin: 4px 0px;
                }
            }
        }

        .Sliders {
            padding: 20px 0px;

            .section {
                padding: 15px 30px;
                border-top: 1px solid rgba(0, 63, 117, 0.8);
            }
        }

        .calcPopup {
            .text {
                padding: 20px;

                .imgs {
                    text-align: center;

                    h4 {
                        margin: 15px 0px 0px 0px;
                        font-family: $fontFamily1;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 21.79px;
                        color: #000;

                    }
                }

                .tables {
                    margin: 20px 0px;

                    table {
                        border-collapse: separate;
                        border-spacing: 0;
                        border-radius: 12px;
                        border: 1px solid rgba(0, 63, 117, 0.2);

                        tbody {
                            border-radius: 12px;
                            border: 1px solid rgba(0, 63, 117, 0.2);

                            tr {
                                td {
                                    font-family: $fontFamily1;
                                    font-size: 9px;
                                    font-weight: 400;
                                    line-height: 16px;
                                    text-align: left;

                                    &.tdRight {
                                        text-align: end;
                                        font-size: 10px;
                                        font-weight: 600;
                                        color: $Greencolor2;

                                    }
                                }

                                &.firstTr {
                                    td {
                                        color: #000;
                                    }
                                }

                                &:first-child {
                                    td {
                                        &:first-child {
                                            background-color: rgba(0, 108, 103, 0.2);
                                            border-top-left-radius: 12px;
                                        }

                                        &:last-child {
                                            background-color: rgba(0, 108, 103, 0.2);
                                            border-top-right-radius: 12px;

                                        }
                                    }
                                }

                                &:last-child {
                                    td {
                                        &:first-child {
                                            border-bottom-left-radius: 12px;
                                        }

                                        &:last-child {
                                            border-bottom-right-radius: 12px;

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .ProfileSection {
        .TopTabs {
            margin-top: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            overflow: hidden;

            .tabs {
                cursor: pointer;
                text-align: center;
                width: calc(100%/5);

                h5 {
                    font-family: $fontFamily1;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 23px;
                    color: rgba(0, 63, 117, 0.5);

                }

                &.active {
                    border-bottom: 2px solid $Greencolor2;

                    h5 {
                        color: $Greencolor2 !important;
                        font-weight: 700 !important;
                    }
                }
            }
        }

        .profileDetails {
            margin-top: 30px;

            .inputcols {
                margin-top: 20px;
            }
        }

        .EditProfileDetails {
            margin-top: 30px;
            position: relative;
            z-index: 1;
            height: 65vh;

            .headingTab {
                display: flex;
                justify-content: space-between;

                h5 {
                    font-family: $fontFamily1;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 23px;
                    text-align: left;
                    color: $Greencolor2;

                }

                svg {
                    color: $Greencolor2;
                }
            }

            .savechangesbtns {
                position: absolute;
                bottom: 5%;
                left: 0;
                right: 0;
            }
        }

        .documentSection {
            .tabss {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $Greencolor2;
                padding: 15px 10px;

                h5 {
                    font-family: $fontFamily1;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 23px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .contactUsPage {
        height: 68vh;
        margin-top: 20px;
        position: relative;
        z-index: 1;

        .info {
            .tabss {
                border-bottom: 1px solid rgba(0, 63, 117, 0.3);
                padding: 15px 0px;

                .text {
                    h5 {
                        font-family: $fontFamily1;
                        color: #000000CC;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 20px;

                    }

                    h6 {
                        color: #000000CC;
                        font-family: $fontFamily1;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;

                    }
                }
            }
        }

        .chaticonBtn {
            position: absolute;
            bottom: 5%;
            right: 5%;

            .circle {
                margin-left: auto;
                width: 50px;
                height: 50px;
                background-color: $Greencolor2;
                padding: 12px 10px;
                cursor: pointer;
                border-radius: 50%;
            }
        }
    }

    .MyDocumentsSection {
        margin-top: 30px;

        .documentBox {
            border-radius: 12px;
            border: 1px solid rgba(0, 108, 103, 0.3);
            border-top: 0px;

            .topTab {
                background-color: rgba(0, 108, 103, 0.5);
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                padding: 10px 15px;
                display: flex;
                justify-content: space-between;

                h6 {
                    font-family: $fontFamily1;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 16px;
                    margin-bottom: 5px;
                }

                h4 {
                    font-family: $fontFamily1;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16px;

                }

                h5 {
                    font-family: $fontFamily1;
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 16px;

                }
            }

            .headings {
                padding: 10px 0px;
                display: flex;
                justify-content: space-between;

                h5 {
                    font-family: $fontFamily1;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    margin-bottom: 0px;
                }

                h6 {
                    font-family: $fontFamily1;
                    font-size: 10px;
                    color: $Greencolor2;
                    font-weight: 600;
                    line-height: 16px;
                    margin-bottom: 0px;
                }

                p {
                    font-family: $fontFamily1;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 16px;
                    margin-bottom: 0px;
                }
            }

            .content {
                padding: 10px;

                .docsBox {
                    padding: 10px;
                    border: 1px solid rgba(0, 108, 103, 0.4);

                    .pdfDiv {
                        display: flex;
                        justify-content: space-between;

                        .imgs {
                            display: flex;
                            align-items: center;

                            h6 {
                                font-family: $fontFamily1;
                                font-size: 10px;
                                font-weight: 400;
                                line-height: 16px;
                                color: $Greencolor2;
                                margin: 0px;

                            }
                        }

                        .eyeIcon {
                            svg {
                                font-size: 16px;
                                cursor: pointer;
                                color: $Greencolor2;
                            }
                        }
                    }
                }
            }
        }

        .docPopup {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;

            .iFrameBox {
                .iframe {
                    width: 100%;
                    height: 350px;
                }
            }
        }
    }

    .FeedbackSection {
        margin-top: 20px;

        .text {
            text-align: center;

            h4 {
                font-family: $fontFamily1;
                font-size: 20px;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: -0.4000000059604645px;

            }

            h5 {
                font-family: $fontFamily1;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: -0.4000000059604645px;
                color: #000000B2;

            }

            .emoji-container {
                display: flex;
                justify-content: space-around;
                width: 100%;
                max-width: 500px;
                margin-top: 20px;

                .emoji-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                    font-size: 2.5rem;
                    transition: transform 0.3s;

                    &:hover {
                        transform: scale(1.3);
                    }
                }
            }


        }
    }

    .NotificationSection {
        margin-top: 20px;

        .topTabs {
            display: flex;

            .tab {
                width: calc(100%);
                border-bottom: 2px solid rgba(0, 63, 117, 0.3);

                h5 {
                    text-align: center;
                    font-family: $fontFamily1;
                    color: rgba(0, 63, 117, 0.7);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;

                }

                &.active {
                    border-bottom: 4px solid rgba(0, 63, 117, 1);

                    h5 {
                        color: $Greencolor2 !important;
                        font-weight: 600 !important;
                    }
                }
            }
        }

        .Messages {
            margin-top: 20px;
            overflow-y: auto;
            height: 75vh;
            // height: 65vh;

            .messageBox {
                cursor: pointer;
                margin-top: 15px;
                border-radius: 10px;
                padding: 10px;

                .imgs {
                    .circle {
                        margin: 0px auto;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                    }
                }

                .text {
                    h4 {
                        margin-bottom: 0px;
                        font-family: $fontFamily1;
                        color: #000;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 22px;

                    }

                    h5 {
                        margin-bottom: 0px;
                        font-family: $fontFamily1;
                        color: #000000B2;
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 22px;
                    }

                    h6 {
                        margin-bottom: 0px;
                        font-family: $fontFamily1;
                        color: #999999;
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 22px;
                    }
                }
            }
        }

        .ViewMail {
            margin-top: 20px;

            .text {
                box-shadow: 5px 5px 10px 0px #dfdfdf;
                padding: 10px;
                border-radius: 12px;
                height: calc(100vh - 35.11vh);
                overflow-y: auto;

                p {
                    font-family: $fontFamily1;
                    text-align: center;
                    margin: 30px 0px;
                    font-size: 20px;
                    font-weight: 500;
                }

                h3 {
                    font-family: $fontFamily1;
                    margin: 20px 0px 0px 0px;
                    font-size: 20px;
                    font-weight: 500;
                }

                h6 {
                    font-family: $fontFamily1;
                    font-size: 14px;
                    color: rgb(93, 92, 92);
                    font-weight: 400;
                }

                h5 {
                    font-family: $fontFamily1;
                    font-size: 16px;
                    font-weight: 500;
                    margin-top: 20px;
                }
            }
        }
    }

    .ErrorPage {
        margin-top: 20px;

        .text {

            h3 {
                margin-top: 40px;
                font-family: $fontFamily1;
                color: #000;
                font-size: 28px;
                font-weight: 700;
                line-height: 24px;
            }

            h6 {
                margin-top: 20px;
                font-family: $fontFamily1;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #000000CC;
            }

        }
    }
}







//animations

.slide-in-left {
    animation: slideInFromLeft 0.3s ease-out forwards;
}

.slide-out-left {
    animation: slideOutFromLeft 0.3s ease-out forwards;
}

.slide-in-right {
    animation: slideInFromRight 0.3s ease-out forwards;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutFromLeft {
    0% {
        transform: translateX(0);
        opacity: 0;
    }

    100% {
        transform: translateX(-100%);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}


// Feedback Form Animations 

.zoom:hover {
    animation: zoom 0.8s ease-out forwards;
}

@keyframes zoom {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.5);
    }
}




// Loader css
.loaderBackground {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.circleLoader {
    .loader {
        color: $Greencolor2;
        font-size: 20px;
        margin: 50% auto;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: relative;
        text-indent: -9999em;
        -webkit-animation: load4 1.3s infinite linear;
        animation: load4 1.3s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@-webkit-keyframes load4 {

    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@keyframes load4 {

    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}



.loader2 {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    .loader {
        margin: 70% auto;
        width: 50px;
        padding: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: $Greencolor2;
        --_m:
            conic-gradient(#0000 10%, #000),
            linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
        mask: var(--_m);
        -webkit-mask-composite: source-out;
        mask-composite: subtract;
        animation: l3 1s infinite linear;
    }

    @keyframes l3 {
        to {
            transform: rotate(1turn)
        }
    }
}